import React from 'react'
import {Paragraph, Title, Title4, LeftSection, RightSection} from '../../components/UI'
import cards from '../../images/cards.jpg'
import styled from 'styled-components'
import locationData from '../../data/locations.json'
import {breakpoints} from '../../utils/helper'
import StorePage from '../../components/StorePage'
import programs from '../../data/programs'

const Image = styled.img`
    width: 100%;
    height: auto;
    
    @media ${breakpoints.desktop} {
        width: 620px;
    }
`

const StyledParagraph = styled(Paragraph)`
    width: 100%;
    margin: 0 0 42px;
    
    @media ${breakpoints.tablet} {
        width: 90%;
    }
`

const RedTitle = styled(Title4)`
    color: ${props => props.theme.colors.red};
    white-space: pre-wrap;
`

const Alkmaar = ({location}) => (
    <StorePage data={locationData.locations[0]} location={location} programs={programs.items}>
        <LeftSection>
            {/*<RedTitle>Tot 25% korting</RedTitle>
            <Title className="padded">Get UP early!</Title>
            <StyledParagraph>Wees er vroeg bij, want bij ons krijg je doordeweeks tussen 8:00 en 9:00 uur UP early korting. Je betaalt dan tot maar liefst 25% minder voor je wasprogramma!</StyledParagraph>*/}
        </LeftSection>
        <RightSection>
            <Image src={cards} alt="Club UP member cards"/>
        </RightSection>
    </StorePage>
)

export default Alkmaar
